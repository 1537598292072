import React, { useState, useEffect } from 'react';
import './normalize.css';
import './App.css';

import imgLogoSymbol from './mochi-logo-symbol.png';
import imgMochiMascot from './mochi-mascot.png';
import imgMochiSymbolM from './mochi-symbol-m.png';

import imgIconTelegram from './iTelegram.png';
import imgIconTelegramSimple from './iconTelegramSimple.png';
import imgIconTelegramWhite from './iTelegramWhite.png';
import imgIconTwitterX from './iTwitterX.png';
import imgIconTwitterXWhite from './iTwitterXWhite.png';
import imgIconTikTok from './iTikTok.png';
import imgIconTikTokWhite from './iTikTokWhite.png';
import imgIconGithub from './iGithub.png';
import imgIconGithubWhite from './iGithubWhite.png';
import imgIconGitbook from './iGitbook.png';
import imgIconGitbookWhite from './iGitbookWhite.png';
import imgIconEmail from './iEmail.png';
import imgIconEmailWhite from './iEmailWhite.png';
import imgIconGlobe from './iGlobe.png';
import imgIconGlobeWhite from './iGlobeWhite.png';
import imgIconBscscan from './iBscscan.png';
import imgIconBscscanWhite from './iBscscanWhite.png';

import imgIconHamburger from './bars-solid.svg';

import imgIconHive from './iconHive.png';
import imgIconHeart from './iconHeartPulseB.png';
import imgIconNft from './iconNftSolid.png';
import imgIconCoin from './iconCoin.png';
import imgIconDapp from './iconDappB.png';

import imgAstroStar1 from './astroStar1.png';
import imgAstroStar2 from './astroStar2.png';
import imgAstroSunSphere from './astroSunSphere.png';
import imgAstroMochiStrawberry from './astroMochiStrawberry.png';
import imgAstroSaturn from './astroSaturn.png';
import imgAstroMochiChocolate from './astroMochiChocolate.png';
import imgAstroAsteroid1 from './astroAsteroid1.png';
import imgAstroAsteroid2 from './astroAsteroid2.png';
import imgAstroAsteroid3 from './astroAsteroid3.png';
import imgAstroAsteroid4 from './astroAsteroid4.png';
import imgAstroAsteroid5 from './astroAsteroid5.png';
import imgAstroAsteroid6 from './astroAsteroid6.png';
import imgAstroAstronaut from './astroAstronaut.png';

import imgIconPath from './iconPath.png';

import imgMochiTokenLogo429x429 from './mochiTokenLogo-429x429.png';

import imgIconBranchLarge from './iBranchLarge.png';

import imgMochiBg1 from './mochiBgPattern1b.jpg';

import imgOpenZeppelinLogo from './openZeppelinLogoA.png';
import imgCyberScopeLogo from './cyberScopeLogo.png';
import imgMochiMagicMaker from './mochiMagicMaker.png';

import imgMochiTokenLogoBig from './mochiOrigBig.png';

import imgMochiNftPreview from './mochiNftPreview.png';
import imgMochiDappPreview from './mochiDappPreview.png';


// NEW ICONS //

import iconSocialsFooterEmail from './iconSocialsFooterEmail.png';
import iconSocialsFooterInstagram from './iconSocialsFooterInstagram.png';
import iconSocialsFooterReddit from './iconSocialsFooterReddit.png';
import iconSocialsFooterTelegram from './iconSocialsFooterTelegram.png';
import iconSocialsFooterTwitter from './iconSocialsFooterTwitter.png';

import iconSocialsHeroEmail from './iconSocialsHeroEmail.png';
import iconSocialsHeroInstagram from './iconSocialsHeroInstagram.png';
import iconSocialsHeroReddit from './iconSocialsHeroReddit.png';
import iconSocialsHeroTelegram from './iconSocialsHeroTelegram.png';
import iconSocialsHeroTwitter from './iconSocialsHeroTwitter.png';


import faEnvelopeSolid from './fa-envelope-solid.svg';

import imgMochiTokenLogoNav from './mochiOrigSmall3.png';

import imgMochiSymbol3d from './mochi-3d-token.png';

import imgPartnerDexTools from './partnerDexToolsSmall.png';
import imgPartnerBscScan from './partnerBscScan.png';
import imgPartnerCyberScope from './partnerCyberScope.png';
import imgPartnerOpenZeppelin from './partnerOpenZeppelin.png';


import imgPartnerGotbit from './partnerGotbit.png';
import imgPartnerBscdaily from './partnerBscdaily.png';
import imgPartnerLunarCrush from './partnerLunarCrush.png';
import imgPartnerXtExchange from './partnerXtExchange.png';
import imgPartnerBiconomy from './partnerBiconomy.png';

import imgMochiPartnerCyberscope from './mochiPartnerCyberscope.png';
import imgMochiPartnerGotbit from './mochiPartnerGotbit.png';
import imgMochiPartnerXtExchange from './mochiPartnerXtExchange.png';
import imgMochiPartnerBiconomy from './mochiPartnerBiconomy.png';
import imgMochiPartnerCoingecko from './mochiPartnerCoingecko.png';
import imgMochiPartnerCoinmarketcap from './mochiPartnerCoinmarketcap.png';
import imgMochiPartnerDextools from './mochiPartnerDextools.png';
import imgMochiPartnerPancakeswap from './mochiPartnerPancakeswap.png';
import imgMochiPartnerOpenZeppelin from './mochiPartnerOpenZeppelin.png';
import imgMochiPartnerBscScan from './mochiPartnerBscScan.png';
import imgMochiPartnerLunarCrush from './mochiPartnerLunarCrush.png';
import imgMochiPartnerBscDaily from './mochiPartnerBscDaily.png';


function App() {

    const [gradientAngle, setGradientAngle] = useState('175.3deg'); // Default angle
    const [error, setError] = useState(null);

    const [isTopBarVisible, setIsTopBarVisible] = useState(true);

    const hideTopBar = () => {
        setIsTopBarVisible(false);
    };

    const [showMorePartners, setShowMorePartners] = useState(false);

    const handleShowMorePartners = () => {
        setShowMorePartners(!showMorePartners);
    };
    

    useEffect(() => {
        const updateGradientAngle = () => {
            const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            // const angle = vw * (175.5 / 1536);
            // const angle = 174;
            const angle = (0.0039 * vw + 169.392).toFixed(4);
            setGradientAngle(`${angle}deg`);
        };

        // Call the function to set the initial angle
        updateGradientAngle();

        // Add event listener to update on resize
        window.addEventListener('resize', updateGradientAngle);

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', updateGradientAngle);
    }, []); // Empty dependency array means this effect runs once on mount


    const [isNavRevealed, setIsNavRevealed] = useState(false);

    const toggleNav = () => {
        setIsNavRevealed(!isNavRevealed);
    };

    

    return (
        <div className="App">
            <div className={`App-topbar ${!isTopBarVisible ? 'hideTopBar' : ''}`}>
                <div class="App-topbar-container">
                    <a href="https://t.me/Mochi_DeFi" target="_blank">
                        <span>We are having lots of fun on <img src={imgIconTelegramSimple} alt="Telegram" />Telegram. Come and join us!</span>
                        <span>Come join the fun on <img src={imgIconTelegramSimple} alt="Telegram" />Telegram!</span>
                    </a>
                    <i className="fa-light fa-xmark" onClick={hideTopBar}></i>
                </div>
            </div>
            <header className="App-header layout-hpad">
                <div className="layout-container">
                    <div className="App-header-mobile">
                        <div className="header-mobile-logo">
                            <a href="https://mochidefi.io">
                                <img src={imgMochiTokenLogoNav} alt="Mochi DeFi Logo" />
                                <span>
                                    <span>Mochi</span><span>DeFi</span>
                                </span>
                            </a>
                        </div>
                        <div className="header-mobile-nav">
                            <button onClick={toggleNav}><i class="fa-sharp fa-regular fa-bars"></i></button>
                            <nav className={`site-nav-mobile ${isNavRevealed ? 'Reveal' : ''}`}>
                                <ul>
                                    <li className="site-nav-mobile-logo">
                                        <a href="https://mochidefi.io">
                                            <img src={imgLogoSymbol} alt="Mochi DeFi Logo" />
                                            <span>
                                                <span>Mochi</span><span>DeFi</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li className="site-nav-mobile-item"><a href="#about" onClick={toggleNav}>About</a></li>
                                    <li className="site-nav-mobile-item"><a href="#token" onClick={toggleNav}>Token</a></li>
                                    <li className="site-nav-mobile-item"><a href="#nft" onClick={toggleNav}>NFT</a></li>
                                    <li className="site-nav-mobile-item"><a href="#dapp" onClick={toggleNav}>Dapp</a></li>
                                    <li className="site-nav-mobile-item"><a href="mailto:hello@mochidefi.io">Contact</a></li>
                                    <li className="site-nav-mobile-button"><a href="https://app.mochidefi.io" className="btn gradient--btn-primary">Launch App <i class="fa-solid fa-arrow-right"></i></a></li>
                                    <li className="site-nav-mobile-close"><button onClick={toggleNav}>X</button></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-mobile-button">
                            <a href="https://app.mochidefi.io">Enter App</a>
                        </div>
                    </div>
                    <div className="App-header-desktop">
                        <nav className="site-nav-desktop">
                            <ul>
                                <li className="site-nav-desktop-logo">
                                    <a href="https://mochidefi.io">
                                        <img src={imgMochiTokenLogoNav} alt="Mochi DeFi Logo" />
                                        <span>
                                            <span>Mochi</span><span>DeFi</span>
                                        </span>
                                    </a>
                                </li>
                                <li className="site-nav-desktop-main">
                                    <li className="site-nav-desktop-item"><a href="#about">About</a></li>
                                    <li className="site-nav-desktop-item"><a href="#token">Token</a></li>
                                    <li className="site-nav-desktop-item"><a href="#nft">NFT</a></li>
                                    <li className="site-nav-desktop-item"><a href="#dapp">Dapp</a></li>
                                    <li className="site-nav-desktop-item"><a href="mailto:hello@mochidefi.io">Contact</a></li>
                                </li>
                                <li className="site-nav-desktop-button"><a href="https://app.mochidefi.io" className="gradient--btn-primary">Launch App <i class="fa-solid fa-arrow-right"></i></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>

            <section className="Section-Hero gradient--hero-cream">
                <div className="layout-hpad">
                    <div className="layout-container">
                        <div className="hero-flexbox">
                            <div>
                                <h1>Meme power with DeFi utility. <span>Mochi.</span></h1>
                                <p className="sub-title">
                                    <span className="hero-subtitle-desktop">Delicious mochi meme-powered defi ecosystem on BSC. Based & cutemaxxed community. Come join us!</span>
                                    <span className="hero-subtitle-mobile">Delicious mochi meme-powered defi ecosystem on BSC.</span>
                                </p>
                                <div className="hero-mochi-token-mobile">
                                    <img src={imgMochiTokenLogoBig} alt="Mochi Token Logo DeFi Cute Character Mascot Smiling" />
                                </div>
                                <div className="social-icons">
                                    <a href="https://t.me/Mochi_DeFi" target="_blank"><i class="fa-brands fa-telegram"></i></a>
                                    <a href="https://twitter.com/Mochi_DeFi" target="_blank"><i class="fa-brands fa-twitter"></i></a>
                                    <a href="mailto:hello@mochidefi.io" target="_blank"><i class="fa-solid fa-envelope"></i></a>
                                </div>
                                <div class="btn-group">
                                    <a className="btn gradient--btn-primary" href="https://app.mochidefi.io/swap?outputCurrency=0x92072F045D0904e9a0cDfD48519f54c83Bf41e82&chain=bsc" target="_blank">Buy on Mochiswap</a>
                                    <a className="btn gradient--btn-primary" href="https://pancakeswap.finance/swap?outputCurrency=0x92072F045D0904e9a0cDfD48519f54c83Bf41e82" target="_blank">Buy on Pancakeswap</a>
                                </div>
                            </div>
                            <div>
                                <img src={imgMochiTokenLogoBig} alt="Mochi Token Logo DeFi Cute Character Mascot Smiling" />
                            </div>
                        </div>
                    </div>
                </div>
                <svg className="Hero-Divider--LightMode" xmlns="http://www.w3.org/2000/svg" width="3600" height="182" viewBox="0 0 1512 182" fill="none" preserveAspectRatio="none">
                    <path d="M716.474 63.2037C921.619 95.7214 1512 0 1512 0V182H0V149.115C0 149.115 511.329 30.6861 716.474 63.2037Z" fill="#FFFFFF"/>
                    <defs>
                        <linearGradient id="paint0_linear_61_2" x1="756" y1="46" x2="756" y2="182" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white"/>
                            <stop offset="1" stop-color="#FEF4F9"/>
                        </linearGradient>
                    </defs>
                </svg>
                <svg className="Hero-Divider--DarkMode" xmlns="http://www.w3.org/2000/svg" width="3600" height="182" viewBox="0 0 1512 182" fill="none" preserveAspectRatio="none">
                    <path d="M729.846 129.324C938.247 195.859 1540 0 1540 0V305.111V692H0V158C0 158 521.444 62.7881 729.846 129.324Z" fill="url(#paint0_linear_2279_67)"/>
                    <defs>
                        <linearGradient id="paint0_linear_2279_67" x1="892" y1="17.8045" x2="925.416" y2="688.742" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#301123"/>
                        <stop offset="0.395" stop-color="#010000"/>
                        <stop offset="0.575" stop-color="#0F060A"/>
                        <stop offset="0.815" stop-color="#2A0C19"/>
                        <stop offset="1" stop-color="#2E0E1B"/>
                        </linearGradient>
                    </defs>
                </svg>
            </section>

            <section className="Section-Stats gradient--strawberry-cream-1" style={{ '--gradient-angle': gradientAngle }}>
                <div className="layout-hpad">
                    <div className="layout-container">
                        {/* <h2 className="stats-heading-desktop">Shaping the future with<br></br><span>MochiDeFi’s</span> unstoppable expansion</h2>
                        <h2 className="stats-heading-mobile">Shaping the future with<br></br><span>MochiDeFi’s</span> unstoppable expansion</h2> */}
                        <h2 className="stats-heading-desktop">Shaping the future with <span>MochiDeFi's</span><br></br>trusted partners & platforms</h2>
                        <h2 className="stats-heading-mobile">Shaping the future with<br></br> <span>MochiDeFi's</span> trusted partners</h2>
                        <div className="partners">
                            <div className={`partners-container ${showMorePartners ? 'expanded' : ''}`}>
                                <div className="partners-row">
                                    <a className="partners-item" target="_blank" href="https://www.cyberscope.io/">
                                        <img src={imgMochiPartnerCyberscope} alt="Mochi DeFi Partner: Cyberscope.io" />
                                    </a>
                                    <a className="partners-item" target="_blank" href="https://gotbit.io/">
                                        <img src={imgMochiPartnerGotbit} alt="Mochi DeFi Partner: Gotbit.io" />
                                    </a>
                                    <a className="partners-item" target="_blank" href="https://www.xt.com/">
                                        <img src={imgMochiPartnerXtExchange} alt="Mochi DeFi Partner: XT Exchange" />
                                    </a>
                                    <a className="partners-item" target="_blank" href="https://www.biconomy.com/">
                                        <img src={imgMochiPartnerBiconomy} alt="Mochi DeFi Partner: Biconomy" />
                                    </a>
                                    <a className="partners-item" target="_blank" href="https://www.coingecko.com/">
                                        <img src={imgMochiPartnerCoingecko} alt="Mochi DeFi Partner: CoinGecko" />
                                    </a>
                                    <a className="partners-item" target="_blank" href="https://coinmarketcap.com/">
                                        <img src={imgMochiPartnerCoinmarketcap} alt="Mochi DeFi Partner: CoinMarketCap" />
                                    </a>
                                    <a className={`partners-item ${showMorePartners ? '' : 'hidden'}`} target="_blank" href="https://www.dextools.io/">
                                        <img src={imgMochiPartnerDextools} alt="Mochi DeFi Partner: DEXTools" />
                                    </a>
                                    <a className={`partners-item ${showMorePartners ? '' : 'hidden'}`} target="_blank" href="https://pancakeswap.finance/">
                                        <img src={imgMochiPartnerPancakeswap} alt="Mochi DeFi Partner: PancakeSwap" />
                                    </a>
                                    <a className={`partners-item ${showMorePartners ? '' : 'hidden'}`} target="_blank" href="https://www.openzeppelin.com/">
                                        <img src={imgMochiPartnerOpenZeppelin} alt="Mochi DeFi Partner: OpenZeppelin" />
                                    </a>
                                    <a className={`partners-item ${showMorePartners ? '' : 'hidden'}`} target="_blank" href="https://bscscan.com/">
                                        <img src={imgMochiPartnerBscScan} alt="Mochi DeFi Partner: BscScan" />
                                    </a>
                                    <a className={`partners-item ${showMorePartners ? '' : 'hidden'}`} target="_blank" href="https://lunarcrush.com/">
                                        <img src={imgMochiPartnerLunarCrush} alt="Mochi DeFi Partner: LunarCrush" />
                                    </a>
                                    <a className={`partners-item ${showMorePartners ? '' : 'hidden'}`} target="_blank" href="https://x.com/bsc_daily">
                                        <img src={imgMochiPartnerBscDaily} alt="Mochi DeFi Partner: BSC Daily" />
                                    </a>
                                </div>
                                <button className="showPartners" onClick={handleShowMorePartners}>
                                    {showMorePartners ? 'See less' : 'See more'}
                                    <i class="fa-solid fa-angle-down"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="akljebg">* Please note: these are placeholder stats only, will be live data after launch. </div>
                <div className="svgContainer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1536" height="60" viewBox="0 0 1536 60" fill="none"  preserveAspectRatio="none">
                        <path d="M1536 60H0V0L1536 60Z" fill="#0F000A"/>
                    </svg>
                </div>
            </section>

            <section id="about" className="Section-About gradient--about-creamy-pink-white">
                <div className="section-bg">
                    <div className="section-bg-wrapper">
                        <div className="section-bg-layer under"></div>
                        <img src={imgMochiBg1} alt="Mochiverse BG 1" />
                        <div className="section-bg-layer over"></div>
                    </div>
                </div>
                {/* <div className="svgContainer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1536" height="60" viewBox="0 0 1536 60" fill="none" preserveAspectRatio="none">
                        <path d="M0 0H1536V60L0 0Z" fill="#FFE1F5"/>
                    </svg>
                </div> */}
                <div className="section-wrapper">
                    <div className="layout-hpad">
                        <div className="layout-container">
                            <div className="about-header">
                                <div class="mochi-m-symbol gradient--primary">
                                    <span>M</span>
                                </div>
                                <div class="mochi-m-symbol-3d">
                                    <img src={imgMochiSymbol3d} alt="Mochi token 3d symbol" />
                                </div>
                                <h2 className="about-heading-desktop">Discover the <span>Ecosystem</span></h2>
                                <h2 className="about-heading-mobile">Discover the <span>Ecosystem</span></h2>
                            </div>
                            <div className="about-flexbox">
                                <div className="astro">
                                    <div className="astro-star1">
                                        <img src={imgAstroStar1} alt="Mochiverse star 1" />
                                    </div>
                                    <div className="astro-star2">
                                        <img src={imgAstroStar2} alt="Mochiverse star 2" />
                                    </div>
                                    <div className="astro-sunsphere">
                                        <img src={imgAstroSunSphere} alt="Mochiverse Sun Sphere" />
                                    </div>
                                    <div className="astro-mochi-strawberry">
                                        <img src={imgAstroMochiStrawberry} alt="Mochiverse Planet Mochi Strawberry" />
                                    </div>
                                    <div className="astro-saturn">
                                        <img src={imgAstroSaturn} alt="Mochiverse Planet Saturn" />
                                    </div>
                                    <div className="astro-mochi-chocolate">
                                        <img src={imgAstroMochiChocolate} alt="Mochiverse Planet Mochi Chocolate" />
                                    </div>
                                    <div className="astro-asteroid3">
                                        <img src={imgAstroAsteroid3} alt="Mochiverse Asteroid #3" />
                                    </div>
                                    <div className="astro-asteroid4">
                                        <img src={imgAstroAsteroid4} alt="Mochiverse Asteroid #4" />
                                    </div>
                                    <div className="astro-asteroid5">
                                        <img src={imgAstroAsteroid5} alt="Mochiverse Asteroid #5" />
                                    </div>
                                    <div className="astro-asteroid2">
                                        <img src={imgAstroAsteroid2} alt="Mochiverse Asteroid #2" />
                                    </div>
                                    <div className="astro-astronaut">
                                        <img src={imgAstroAstronaut} alt="Mochiverse Astronaut" />
                                    </div>
                                    <div className="astro-asteroid1">
                                        <img src={imgAstroAsteroid1} alt="Mochiverse Asteroid #1" />
                                    </div>
                                    <div className="astro-asteroid6">
                                        <img src={imgAstroAsteroid6} alt="Mochiverse Asteroid #6" />
                                    </div>
                                </div>
                                <div className="about-column">
                                    <div className="about-item">
                                        <div className="about-item-heading">
                                            <div className="about-icon graident--primary-icon"><i class="fa-sharp fa-solid fa-heart-pulse "></i></div>
                                            <h3 className="section-about-card-heading-desktop">The heartbeat of Mochi DeFi</h3>
                                            <h3 className="section-about-card-heading-mobile">The heartbeat of Mochi DeFi</h3>
                                        </div>
                                        <p>The $MOCHI Token isn't just another memecoin, it's the heartbeat of the Mochi DeFi ecosystem, combining meme power + utility.</p>
                                        <a className="btn-alt" href="#token">Mochi Token</a>
                                        
                                    </div>
                                    <div className="about-item">
                                        <div className="about-item-heading">
                                            <div className="about-icon graident--primary-icon"><i class="fa-sharp fa-solid fa-hexagon-vertical-nft"></i></div>
                                            <h3 className="section-about-card-heading-desktop">Power-up utility NFTs</h3>
                                            <h3 className="section-about-card-heading-mobile">Power-up utility NFTs</h3>
                                        </div>
                                        <p>Mochi NFTs are designed to synergize with the $MOCHI Token, unlocking exclusive perks, enhanced yield potentials, and special features.</p>
                                        <a className="btn-alt" href="#nft">Mochi NFTs</a>
                                    </div>
                                    <div className="about-item">
                                        <div className="about-item-heading">
                                            <div className="about-icon graident--primary-icon"><i class="fa-solid fa-browser"></i></div>
                                            <h3 className="section-about-card-heading-desktop">Stake & earn with Mochi Dapp</h3>
                                            <h3 className="section-about-card-heading-mobile">Stake & earn with Mochi Dapp</h3>
                                        </div>
                                        <p>DeFi platform built to maximize your financial opportunities and facillitate your next banger of a trade.</p>
                                        <a className="btn-alt" href="#dapp">Mochi DAPP</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="token" className="Section-Mochi-Token gradient--strawberry-cream-2">
                <div className="layout-hpad">
                    <div className="layout-container">
                        <div className="mochi-token-flexbox">
                            <div className="mochi-token-flexbox-column1">
                                <div className="content-headingX">
                                    <h2>Mochi <span>Token</span></h2>
                                    <p>Ultra clean CA built on Open Zeppelin's industry standard ERC20 smart contract framework: audited, certified, open source, built with love, ready for moon.</p>
                                    <p>Combining meme power and utility, $MOCHI Token is the heartbeat of the Mochi DeFi ecosystem.</p>
                                </div>
                                <div className="content-grid">
                                <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>250</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>Quadrillion Supply</p>
                                        </div>
                                    </div>
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>44%</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>Available for Presale</p>
                                        </div>
                                    </div>
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>21%</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>Initial Liquidity</p>
                                        </div>
                                    </div>
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>15%</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>Staking</p>
                                        </div>
                                    </div>
                                    <div className="content-grid-item content-grid-item--has-note">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>16%</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>Ecosystem & CEX Reserves</p>
                                        </div>
                                    </div>
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>4%</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>Marketing</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-group">
                                    <a className="btn gradient--btn-primary" href="https://app.mochidefi.io/swap?outputCurrency=0x92072F045D0904e9a0cDfD48519f54c83Bf41e82&chain=bsc" target="_blank">Buy on Mochiswap</a>
                                    <a className="btn gradient--btn-primary" href="https://pancakeswap.finance/swap?outputCurrency=0x92072F045D0904e9a0cDfD48519f54c83Bf41e82" target="_blank">Buy on Pancakeswap</a>
                                </div>
                            </div>
                            <div className="mochi-token-flexbox-column2">
                                <img src={imgMochiTokenLogoBig} alt="$MOCHI Token Logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="nft" className="Section-Mochi-Nft gradient--dark-cherry-nft">
                <div className="layout-hpad">
                    <div className="layout-container">
                        <div className="mochi-nft-flexbox">
                            <div className="mochi-nft-flexbox-column1">
                                <div className="content-headingX">
                                    <h2>Mochi <span>NFT</span></h2>
                                    <p>Beyond art: Mochi NFTs serve as keys to a broader realm of possibilities within the Mochi ecosystem.</p>
                                    <p>Whether your intention is to collect, trade, stake, or simply pimp out your pfp and show off to the community - Mochi NFTs offer fun & profitable opportunities for all Mochi holders.</p>
                                </div>
                                <div className="content-grid content-grid--dark">
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>NFT Staking & Utilities</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>NFT’s with real utility, more than just based and cutemaxxed collectables, your Mochi NFT unlocks exclusive rewards, higher yields, and access to premium utilities.</p>
                                        </div>
                                    </div>
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>NFT Marketplace & Launchpad</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>Exclusive Marketplace for vetted & approved projects only, protecting our community and providing alpha with peace of mind.* Approved launchpads transacted in $MOCHI, with a portion burned, increasing buy pressure and deflationary nature of the ecosystem.</p>
                                        </div>
                                    </div>
                                </div>
                                <a className="btn gradient--btn-primary" href="#nfts-coming-soon">Coming Soon</a>
                            </div>
                            <div className="mochi-nft-flexbox-column2">
                                <img src={imgMochiNftPreview} alt="$MOCHI Token Logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="dapp" className="Section-Mochi-Dapp gradient--dapp-cream">
                <div className="layout-hpad">
                    <div className="layout-container">
                        <div className="mochi-dapp-flexbox">
                            <div className="mochi-dapp-flexbox-column1">
                                <div className="content-headingX">
                                    <h2>Mochi <span>Dapp</span></h2>
                                    <p>Stake, trade, earn, and level-up your DeFi prowess.</p>
                                    <p>A suite of products to maximize financial opportunities for both traders & project owners. Enchanting mochi creations with a dash of digital delight.</p>
                                </div>
                                <div className="content-grid">
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>Staking Platform</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>Built for both Mochi holders + the wider BSC ecosystem. A staking platform with real utility that reaches beyond just the Mochi community.</p>
                                        </div>
                                    </div>
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>Revenue Sharing</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>We create real revenue generating products, with profit sharing avenues for Mochi investors.</p>
                                        </div>
                                    </div>
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>Tools & Utilities</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>An array of tools & utilities at your fingertips, built by actual traders and old school bsc developers who have been in the trenches for years.</p>
                                        </div>
                                    </div>
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>And much more...</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>We are building the next great DeFi platform on BSC, with oldschool community vibes, and everything we've imagined as traders and wish someone else would build.</p>
                                        </div>
                                    </div>
                                    <div className="content-grid-item">
                                        <div className="content-grid-item-heading">
                                            <img src={imgIconPath} alt="Icon: Path" />
                                            <h3>Project Owner? Get In Touch</h3>
                                        </div>
                                        <div className="content-grid-item-body">
                                            <p>Are you a project owner looking to offer staking to your investors? We have built a unique staking platform that allows us to deploy partnership pools for your project. Please get in touch to discuss a potential partnership that will take your project to the next level.</p>
                                            <p>Email us: hello@mochidefi.io</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-group">
                                    <a className="btn gradient--btn-primary" href="https://app.mochidefi.io" target="_blank">Launch App</a>
                                    {/* <a className="btn" href="https://pancakeswap.com" target="_blank">Help and Support Docs</a> */}
                                </div>
                            </div>
                            <div className="mochi-dapp-flexbox-column2">
                                <img src={imgMochiDappPreview} alt="$MOCHI Token Logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="Section-Next gradient--silver-cream-everyone ">
                <div className="layout-hpad">
                    <div className="layout-container">
                        {/* <div className="content-headingX">
                            <h2 className="next-heading-desktop">Made for everyone to enjoy</h2>
                            <h2 className="next-heading-mobile">Made for<br></br>everyone to enjoy</h2>
                            <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut lobortis ex ut tellus facilisis, ut blandit purus interdum.</p>
                        </div> */}
                        <div className="about-column about-column--mbot64s">
                            <div className="about-item">
                                <div className="about-item-heading">
                                    <img src={imgCyberScopeLogo} alt="Logo: Cyber Scope" />
                                    <h3>Audit by<br></br>Cyber Scope</h3>
                                </div>
                                {/* <p>Integer auctor magna non nulla lacinia, eget commodo elit hendrerit. Sed bibendum in felis vel consectetur. Morbi eget quam vel sapien fringilla hendrerit feugiat.</p> */}
                                <a className="btn-alt" href="https://github.com/cyberscope-io/audits/blob/main/1-mochi/audit.pdf">View Audit</a>
                            </div>
                            <div className="about-item">
                                <div className="about-item-heading">
                                    <img src={imgOpenZeppelinLogo} alt="Logo: Open Zeppelin" />
                                    <h3>Built with<br></br>Open Zeppelin</h3>
                                </div>
                                {/* <p>Ultra secure token contract built on Open Zeppelin's industry standard ERC20 framework, the most trusted & battle tested contract framework in DeFi and still the gold standard in 2024.</p> */}
                                <a className="btn-alt" href="https://github.com/OpenZeppelin/openzeppelin-contracts/blob/master/contracts/token/ERC20/ERC20.sol">Diff-Check Mochi</a>
                            </div>
                            {/* <div className="about-item">
                                <div className="about-item-heading">
                                    <img src={imgIconHive} alt="Icon: Hive Symbol" />
                                    <h3>Morbi eget<br></br>Quam Vel Sapien.</h3>
                                </div>
                                <p>Quisque interdum purus et tellus porta tristique. Maecenas nulla mi, interdum et tristique in.</p>
                                <a className="btn-alt" href="#">Learn More</a>
                            </div>
                            <div className="about-item">
                                <div className="about-item-heading">
                                    <img src={imgMochiMagicMaker} alt="Logo: Mochi Magic" />
                                    <h3>Certified<br></br>Mochi Magic Maker</h3>
                                </div>
                                <p>Officially endorsed by The Institute for Magical Mochi & Meme Culinary Arts: Sprinkling a little sugar, spice, and everything nice into the world of blockchain.</p>
                                <a className="btn-alt" href="#">Make Mochi Magic</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="Section-Another">

            </section>

            <footer>
                <div className="layout-hpad">
                    <div className="layout-container">
                        <div className="footer-top">
                            <div>
                                <div className="footer-logo">
                                    <a href="https://mochidefi.io">
                                        <img src={imgLogoSymbol} alt="Mochi DeFi Logo" />
                                        <span>
                                            <span>Mochi</span><span>DeFi</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div className="footer-cta">
                                    <a className="btn gradient--btn-primary" href="https://app.mochidefi.io/">Launch App<i class="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div>
                                <div className="footer-socials">
                                    <div className="social-icons">
                                        <a href="https://t.me/Mochi_DeFi" target="_blank"><i class="fa-brands fa-telegram"></i></a>
                                        <a href="https://twitter.com/Mochi_DeFi" target="_blank"><i class="fa-brands fa-twitter"></i></a>
                                        <a href="mailto:hello@mochidefi.io" target="_blank"><i class="fa-solid fa-envelope"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-divider"></div>
                        <div className="footer-bottom">
                            {/* <div>
                                <ul className="footer-nav">
                                    <li><a href="https://github.com/mochi-defi">Github</a></li>
                                    <li><a href="https://mochidefi.gitbook.io/">Docs</a></li>
                                    <li><a href="mailto:hello@mochidefi.io">Contact Us</a></li>
                                </ul>
                            </div> */}
                            <div>
                                <div className="footer-powered">
                                    <p>Powered by</p>
                                    <a href="https://mochidefi.io">
                                        <img src={imgLogoSymbol} alt="Mochi DeFi Logo" />
                                        <span>
                                            <span>Mochi</span><span>DeFi</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default App;
